body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #FFF;
  }
  
  .container {
    max-width: 80em;
    margin: 0 auto;
    padding: 0 1em;
  }
  
  header {
    position: relative;
    padding: 10em 0 8em;
    z-index: 2;
    height: 600px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  header .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  
  header .video-background iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vw;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 2em;
  }
  
  .left-content {
    flex: 1;
    margin-right: 2em;
  }
  
  .left-content h1 {
    font-size: 2.5em;
    text-transform: uppercase;
    margin-bottom: 1em;
  }
  
  .left-content p {
    font-size: 1.3em;
    line-height: 1.2;
  }
  
  .right-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .right-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Media Queries para pantallas pequeñas */
  @media (max-width: 768px) {
    header {
      padding: 4em 1em 3em;
      height: auto;
    }
  
    header .container {
      flex-direction: column;
      align-items: center;
    }
  
    .left-content {
      margin-right: 0;
      width: 100%;
      text-align: center;
    }
  
    .left-content h1 {
      font-size: 1.3em; /* Tamaño más pequeño para h1 */
      line-height: 1.1; /* Ajuste de altura de línea */
    }
  
    .left-content p {
      font-size: 0.8em; /* Tamaño más pequeño para párrafos */
      line-height: 1.4; /* Mejor espaciado entre líneas */
    }
  
    .right-content {
      display: none !important; /* Oculta la imagen */
    }
  }
  
  /* Media Queries para pantallas muy pequeñas (móviles) */
  @media (max-width: 480px) {
    header {
      padding: 2.5em 1em 2.5em;
    }
  
    .left-content h1 {
      font-size: 1.1em; /* Aún más pequeño en dispositivos móviles */
      line-height: 1.1;
    }
  
    .left-content p {
      font-size: 0.7em; /* Tamaño de párrafo aún más pequeño */
      line-height: 1.3;
    }
  }
  